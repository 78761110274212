<template>
  <form class="card-view" @submit.prevent="validate" novalidate>
    <Title class="card-view__title">
      Привяжите свою банковскую карту<br />
      для получения решения и активации заявки
    </Title>
    <Highlight class="card-view__highlight">
      Требования к карте: не нулевой баланс, наличие транзакций за последнии 30
      дней.
    </Highlight>
    <div class="card-view__description" v-html="description"></div>
    <Card ref="card" />
    <div class="card-view__actions">
      <Button type="submit"> Продолжить </Button>
      <Button back @click.native="back"> Назад </Button>
    </div>
  </form>
</template>

<script>
import sendCard from "@index/api/sendCard";
import checkCard from "@index/api/checkCard";

import Card from "@index/components/application/card/Card";
import Title from "@index/components/gui/title/Title";
import Button from "@index/components/gui/button/Button";

import store from "@index/store";

import "./card-view.scss";

export default {
  computed: {
    description() {
      return this.$DICTIONARY.comsa;
    },
  },
  methods: {
    validate() {
      this.$refs.card.validate();
      this.$refs.card.isValid && this.submit();
    },
    async submit() {
      try {
        await checkCard(this.$refs.card.form.number);
      } catch (e) {
        this.$refs.card.error = "Невалидная карта";

        return;
      }

      const card = {
        card: this.$refs.card.form.number,
        date: this.$refs.card.form.date,
        cvv: this.$refs.card.form.cvv,
        name: this.$refs.card.form.holder_name,
      };

      const data = await sendCard(card);

      this.$router.push({
        name: "CardSecure",
        params: {
          data,
          card,
        },
      });
    },
    back() {
      this.$router.push({ name: "PersonalInfo" });
    },
  },
  components: {
    Title,
    Card,
    Button,
    Highlight: () => import("@index/components/gui/highlight/Highlight"),
  },
  beforeRouteEnter(to, from, next) {
    const { allow } = to.params;
    const {
      passportData: { passportnumber },
      isSigned,
    } = store.getters["application/user"];

    if ((allow || isSigned) && passportnumber) {
      next();
    } else {
      store.commit("application/load", false);
      next({ name: "ContactInfo" });
    }
  },
};
</script>
